<template>
  <div class="news1">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="18" style="margin-bottom: 56px">
          <div style="background-color: #fff">
            <div class="news1__title-container">
              <span>首页 > </span>
              <span>新闻资讯 > </span>
              <span>资讯详情</span>
            </div>
            <div class="news1__content-left">
              <div class="news1-title">PCBA测试组装内容及工艺注意事项有哪些</div>
              <div class="news1-createTime">
                <span>发布时间： 2021/1/19 10:52:13 </span>
                <span>浏览： 68次</span>
              </div>
              <div style="font-size: 16px">
                <p class="news1__p-1">
                  为了保证产品的稳定性、精准度，避免因为组装工艺、操作方法等导致产品不必要的返修，pcba厂商会采用PCBA测试组装的方式来解决。不过，业内人士也强调，PCBA测试组装并不是万能的，在实际操作过程中，也有很多需要注意的地方。究竟是哪些呢？</p>

                <div class="news1-subtitle">PCBA测试组装工艺需要注意的事情</div>

                <p class="news1__p-2">
                  1、当PCBA需要升级的时候，主板一般都会有中途变更项目。对此，业内人士强调，正常批次的不同时间段pcba主板，不能和售后主板混在一起生产，因为一旦混在一起生产，极容易导致混板出现，这对电子产品的后期使用是不利的。所以，在PCBA测试组装中，要确定软件的最终使用是否要按照客户的要求来做。
                </p>
                <p class="news1__p-3">2、在进行PCBA测试组装之前，先要将手指上的脏污去掉，最好用无尘布酒精擦拭一遍，然后再进行操作，这样能避免用户手中的电路板因为这些脏污导致某些键操作不良。</p>
                <p class="news1__p-4">
                  3、防静电，无论是PCBA测试组装还是其他工艺，都要做好防静电措施，这一步的作用在于避免电子产品在使用过程中出现接触不良或者是通电等情况，能提高产品的品质和使用体验。</p>

                <div class="news1-subtitle" style="margin-top: 20px">PCBA测试组装都包括哪些内容？</div>
                <p style="text-indent: 2em;margin: 10px 0">1、ICT测试</p>
                <p style="line-height: 1.5">这个环节主要包括电路的通断、电压以及电流数值、振幅、噪音等测试。</p>
                <p style="text-indent: 2em;margin: 10px 0">2、FCT测试</p>
                <p style="line-height: 1.5">其目的是在IC程序烧制的时候，对整个PCBA电路板进行测试，看看软硬件有没有问题，同时为其配备相应的贴片加工治具以及测试架。</p>
                <p style="text-indent: 2em;margin: 10px 0">3、疲劳测试</p>
                <p style="line-height: 1.5">即对PCBA板子进行抽样，然后进行功能测试，包括长时间操作、高频等，看看是否有失效的情况，判断其出现故障的概率。</p>
                <p style="text-indent: 2em;margin: 10px 0">4、恶劣环境测试</p>
                <p style="line-height: 1.5">包含对电路板在极限环境下的测试，像温湿度、跌落、溅水、震动等，获得随机样本，推断整个pcba电路板的质量可靠性。</p>
                <p style="text-indent: 2em;margin: 10px 0">5、老化测试</p>
                <p style="line-height: 1.5">
                  主要是通过将pcba板子以及电子产品进行长时间通电，然后保持起工作状态，查看是否有故障。值得注意的是，只有经过这一步测试之后，才能投入市场、出厂销售。
                </p>
                <p style="text-indent: 2em;margin: 10px 0;line-height: 1.5;padding-bottom: 64px;border-bottom: 1px solid #d4d4d4">
                  PCBA测试组装工艺比较复杂，在整个生产过程中占据重要地位。如果没有这个环节，产品就可能出现高故障、高返修的情况，这对企业发展是非常不利的。有了PCBA测试组装环节之后，企业能及时了解产品的质量和使用效果，继而根据出现的问题及时调整，确保产品的可靠性，为企业发展和品牌的树立创造良好的环境。</p>
              </div>

              <div style="padding-top: 20px;padding-bottom: 40px">
                <!-- <span style="color: #A6A6A6;font-size: 16px">< 上一篇</span> -->
				
                <router-link to="/news2">
                  <span class="fl" style="color: #DA261D;font-size: 16px">上一篇 ></span>
                </router-link>
				  <span class="fr" style="color: #A6A6A6;font-size: 16px">下一篇 ></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="background-color: #fff;padding: 24px">
            <div style="font-size: 20px;font-weight: bold">热点资讯</div>
            <div style="border-bottom: 1px solid #D4D4D4">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验有哪些标准</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验SMT对于电子行……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">什么是PCBA代工代料，PCBA……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news1 {
  background: #F6F6F6;

  .container {
    margin-top: 24px;
    padding: 0 gap();
  }
}

.news1__title-container {
  font-size: 14px;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  span:nth-of-type(3) {
    color: #a6a6a6;
  }
}

.news1__content-left {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;

  .news1-title {
    font-size: 24px;
    font-weight: bold;
  }

  .news1-createTime {
    padding: 10px 0;
    font-size: 14px;
    color: #A6A6A6;
    border-bottom: 1px solid #D4D4D4;

    span:first-of-type {
      margin-right: 32px;
    }
  }

  .news1__p-1 {
    margin: 20px 0;
    text-indent: 2em;
    line-height: 1.5;
  }

  .news1__p-2,
  .news1__p-3,
  .news1__p-4 {
    text-indent: 2em;
    line-height: 1.5;
  }

  .news1-subtitle {
    font-weight: bold;
    text-indent: 2em;
    margin-bottom: 10px;
  }
}
</style>
